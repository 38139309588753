export const styles = {
  favoriteIcon: {
    top: 0,
    right: 0,
    zIndex: 2,
    width: "48px",
    height: "48px",
    padding: "12px",
    cursor: "pointer",
    position: "absolute",
  },

  favoriteIconLoading: {
    transition: "0.2s",

    animation: "heartbeat 0.5s infinite linear",

    "@keyframes heartbeat": {
      to: {
        transform: "scale(0.9)",
      },
      from: {
        transform: "scale(1.1)",
      },
    },
  },

  favoriteIconDesktop: {
    zIndex: 10,
    top: "17px",
    padding: "0",
    right: "17px",
    width: "24px",
    height: "24px",
    display: "flex",
    cursor: "pointer",
    transition: "0.2s",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",

    "&:active": {
      transform: "scale(0.9)",
    },
  },
};
