/* eslint-disable perfectionist/sort-objects */
import { createEvent, createStore } from "effector";

export const $likedHotels = createStore<null | {
  [key: string]: boolean;
}>(null);

export const addHotelToLikedHotels = createEvent<string>();
export const removeHotelFromLikedHotels = createEvent<string>();

$likedHotels
  .on(addHotelToLikedHotels, (state, id) => ({
    ...state,
    [id]: true,
  }))
  .on(removeHotelFromLikedHotels, (state, id) => ({
    ...state,
    [id]: false,
  }));
