/* eslint-disable perfectionist/sort-objects */
import { split, sample, restore, createEvent } from "effector";
import { combineEvents } from "patronum";

import {
  LIKE_LOGIN_MESSAGE,
  LIKE_SUCCESS_MESSAGE,
  DISLIKE_SUCCESS_MESSAGE,
  MISSING_HOTEL_ID_MESSAGE,
} from "../lib/contants";

import { DEFAULT_ERROR } from "@/shared/lib/constants";
import { authCompleted } from "@/shared/model/api/auth";
import {
  addFavouriteHotelMutation,
  removeFavouriteHotelMutation,
} from "@/shared/model/api/favourites";
import { getCountersQuery } from "@/shared/model/api/profile";
import { openAuthModal } from "@/shared/model/store/auth";
import {
  addHotelToLikedHotels,
  removeHotelFromLikedHotels,
} from "@/shared/model/store/favourites";
import { openNotification } from "@/shared/model/store/notifications";
import { TNotice } from "@/shared/model/types/common";

type TFavouritesState = {
  isLiked: boolean;
  isLoggedIn: boolean;
  hotelId: null | string;
};

export const toggleHotelLike = createEvent<TFavouritesState>();

export const addHotelToFavourites = createEvent<TFavouritesState>();
export const removeHotelFromFavourites = createEvent<TFavouritesState>();
export const noHotelIdError = createEvent<TFavouritesState>();
export const noAuthError = createEvent<TFavouritesState>();

const $favouritesState = restore<TFavouritesState>(toggleHotelLike, {
  isLoggedIn: false,
  isLiked: false,
  hotelId: null,
});

split({
  source: toggleHotelLike,
  match: {
    noHotelIdError: $favouritesState.map(({ hotelId }) => !hotelId),
    noAuthError: $favouritesState.map(({ isLoggedIn }) => !isLoggedIn),
    isLiked: $favouritesState.map(
      ({ isLoggedIn, isLiked }) => isLoggedIn && isLiked,
    ),
    isDisliked: $favouritesState.map(
      ({ isLoggedIn, isLiked }) => isLoggedIn && !isLiked,
    ),
  },
  cases: {
    noHotelIdError: noHotelIdError,
    noAuthError: noAuthError,
    isLiked: removeHotelFromFavourites,
    isDisliked: addHotelToFavourites,
  },
});

const afterAuthCallback = combineEvents([noAuthError, authCompleted]);

sample({
  // TODO: types
  // @ts-expect-error
  clock: afterAuthCallback,
  source: $favouritesState,
  fn: ({ hotelId }) => hotelId as string,
  target: addHotelToFavourites,
});

sample({
  clock: noHotelIdError,
  fn: () =>
    ({
      severity: "error",
      message: MISSING_HOTEL_ID_MESSAGE,
    }) as TNotice,
  target: openNotification,
});

sample({
  clock: noAuthError,
  fn: () =>
    ({
      severity: "error",
      message: LIKE_LOGIN_MESSAGE,
    }) as TNotice,
  target: [openNotification, openAuthModal],
});

sample({
  clock: addHotelToFavourites,
  source: $favouritesState,
  fn: ({ hotelId }) => hotelId as string,
  target: [addFavouriteHotelMutation.start, addHotelToLikedHotels],
});

sample({
  clock: removeHotelFromFavourites,
  source: $favouritesState,
  fn: ({ hotelId }) => hotelId as string,
  target: [removeFavouriteHotelMutation.start, removeHotelFromLikedHotels],
});

sample({
  clock: addFavouriteHotelMutation.finished.success,
  fn: () =>
    ({
      severity: "success",
      message: LIKE_SUCCESS_MESSAGE,
    }) as TNotice,
  target: [openNotification, getCountersQuery.refresh],
});

sample({
  clock: removeFavouriteHotelMutation.finished.success,
  fn: () =>
    ({
      severity: "success",
      message: DISLIKE_SUCCESS_MESSAGE,
    }) as TNotice,
  target: [openNotification, getCountersQuery.refresh],
});

sample({
  clock: [
    addFavouriteHotelMutation.finished.failure,
    removeFavouriteHotelMutation.finished.failure,
  ],
  fn: ({ error }) =>
    ({
      severity: "error",
      message: error?.message ?? DEFAULT_ERROR,
    }) as TNotice,
  target: [openNotification],
});
