import { attach } from "effector";

import { TFFRequest, TPaginationPayload } from "../../types/common";
import { TFavoriteHotels } from "../../types/hotels";

import { requestFx } from "@/shared/config";

type TFavouriteListResponse = TFFRequest<TFavoriteHotels>;

export const getFavouriteHotelsFx = attach<
  TPaginationPayload,
  TFavouriteListResponse
>({
  effect: requestFx,
  mapParams: ({ page, per_page }) => ({
    url: "favorite/hotels",
    params: {
      page,
      per_page,
    },
  }),
});

export const postFavouriteHotelFx = attach<string, TFFRequest<void>>({
  effect: requestFx,
  mapParams: (id) => ({
    method: "post",
    url: `favorite/hotels/${id}`,
  }),
});

export const deleteFavouriteHotelFx = attach<string, TFFRequest<void>>({
  effect: requestFx,
  mapParams: (id) => ({
    method: "delete",
    url: `favorite/hotels/${id}`,
  }),
});
