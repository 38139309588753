import { createQuery, createMutation } from "@farfetched/core";

import {
  getFavouriteHotelsFx,
  postFavouriteHotelFx,
  deleteFavouriteHotelFx,
} from "./endpoints";

import { QueryTools, MutationTools } from "@/shared/lib/helpers/farfetched";

export const addFavouriteHotelMutation = createMutation({
  effect: postFavouriteHotelFx,
  name: "addFavouriteHotelMutation",
});

export const removeFavouriteHotelMutation = createMutation({
  effect: deleteFavouriteHotelFx,
  name: "removeFavouriteHotelMutation",
});

export const getFavouriteHotelQuery = createQuery({
  effect: getFavouriteHotelsFx,
  name: "getFavouriteHotelQuery",
  mapData: ({ result }) => result.data.data,
});

MutationTools(addFavouriteHotelMutation).errors.handleAll();
MutationTools(removeFavouriteHotelMutation).errors.handleAll();
QueryTools(getFavouriteHotelQuery).errors.handleAll();
