import { FC, memo, useMemo } from "react";

import { SxProps, IconButton } from "@mui/material";
import { useUnit } from "effector-react";

import { MOBILE_LIKE_SIZE, DESKTOP_LIKE_SIZE } from "../lib/contants";
import { toggleHotelLike } from "../model";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useSearchBreakpoints } from "@/shared/lib/hooks";
import {
  addFavouriteHotelMutation,
  removeFavouriteHotelMutation,
} from "@/shared/model/api/favourites";
import { $isLoggedIn } from "@/shared/model/store/auth";
import { $likedHotels } from "@/shared/model/store/favourites";
import { ESearchType } from "@/shared/model/types/search";
import { LoaderButton } from "@/shared/ui";

import LikeIconDefault from "@/public/icons/likes/like_default.svg";
import LikeIconLiked from "@/public/icons/likes/like_liked.svg";

type TLikeIcon = {
  size: number;
  inFavourites: boolean;
};

type TConditionalTexts = {
  likedText: string;
  defaultText: string;
};

type TProps = {
  id?: string;
  hotelId: string;
  wrapperSx?: SxProps;
  inFavourites: boolean;
  texts?: TConditionalTexts;
  children?: string | JSX.Element;
};

const LikeIcon: FC<TLikeIcon> = ({ inFavourites, size = MOBILE_LIKE_SIZE }) =>
  inFavourites ? (
    <LikeIconLiked width={size} height={size} />
  ) : (
    <LikeIconDefault width={size} height={size} />
  );

export const LikeButton: FC<TProps> = memo(
  ({ id, texts, hotelId, children, inFavourites, wrapperSx = {} }) => {
    const isDesktop = useSearchBreakpoints({
      type: ESearchType.Main,
    });

    const [likedHotelsList, toggleHotelLikeFn, isLoggedIn] = useUnit([
      $likedHotels,
      toggleHotelLike,
      $isLoggedIn,
    ]);

    const isInFavouritesList = likedHotelsList?.[hotelId] ?? inFavourites;

    const { pending: isLikeLoading } = useUnit(addFavouriteHotelMutation);
    const { pending: isDislikeLoading } = useUnit(removeFavouriteHotelMutation);

    const isLikeButtonLoading = isLikeLoading || isDislikeLoading;

    const buttonStyles = useMemo(
      () => (isDesktop ? styles.favoriteIconDesktop : styles.favoriteIcon),
      [isDesktop],
    );

    const buttonLoadingStyles = useMemo(
      () => (isLikeButtonLoading ? styles.favoriteIconLoading : {}),
      [isLikeButtonLoading],
    );

    const likeSize = isDesktop ? DESKTOP_LIKE_SIZE : MOBILE_LIKE_SIZE;

    const onClick = () => {
      toggleHotelLikeFn({
        hotelId,
        isLoggedIn,
        isLiked: isInFavouritesList,
      });
    };

    if (children || texts) {
      return (
        <LoaderButton
          id={id}
          sx={wrapperSx}
          onClick={onClick}
          loading={isLikeButtonLoading}
          customIcon={
            <LikeIcon size={likeSize} inFavourites={isInFavouritesList} />
          }
        >
          <Condition match={Boolean(texts)}>
            {isInFavouritesList ? texts?.likedText : texts?.defaultText}
          </Condition>
          <Condition match={!texts}>{children}</Condition>
        </LoaderButton>
      );
    }

    return (
      <IconButton
        id={id}
        onClick={onClick}
        disabled={isLikeButtonLoading}
        sx={{ ...buttonStyles, ...buttonLoadingStyles }}
      >
        <LikeIcon size={likeSize} inFavourites={isInFavouritesList} />
      </IconButton>
    );
  },
);
